import { Role } from "@/acl/roles";
import { HELVETISCHE_BANK_PRODUCTS_DETAILS } from "@/assets/constants/products/helvetische-bank";

export default {
  orgName: "Helvetische Bank",
  logoPath: require("@/assets/images/logo/helvetische-bank.png"),
  navBarLogoStyle: "width: 100%; max-height: 100px;",
  allowedLoginMethods: ["Google"],
  withProductsFilteringAbility: true,
  withProductsFilteringAbilityOnHome: true,
  numberOfProductsToShow: 6,
  numberOfProductsToShowOnHome: 6,
  products: HELVETISCHE_BANK_PRODUCTS_DETAILS,
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en", "de"],
  colorScheme: ["#4b6885", "#7a2110", "#1a171b", "#8f7446", "#4a5d36", "#665475"],
};
